import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import classnames from "classnames";

import Page from "../page/page";

import illu_chap1 from "../../assets/illu_chap1.svg";
import illu_chap2 from "../../assets/illu_chap2.svg";
import illu_chap3 from "../../assets/illu_chap3.svg";
import illu_chap4 from "../../assets/illu_chap4.svg";
import nopicture from "../../assets/nopicture.svg";
import pilot from "../../assets/pilot.png";
import episode0 from "../../assets/cards/kamenganomi.png";
import episode1 from "../../assets/cards/basu.png";
import episode2 from "../../assets/cards/mechakyojin.png";
import episode3 from "../../assets/cards/elasticogun.png";
import episode4 from "../../assets/cards/kirakiraawa.png";
import episode5 from "../../assets/cards/shotouBunshin.png";
import episode6 from "../../assets/cards/saikiSakujo.png";
import episode7 from "../../assets/cards/bachisoryou.png";
import episode8 from "../../assets/cards/kagemanshin.png";
import episode9 from "../../assets/cards/aisukuriimu.png";


import "./home.scss";

type Milestone = {
  id: number;
  type: "episode" | "event" | "coming";
  title: string;
  description: string;
  illu: any;
  link: string;
  subtitle?: string;
};

type Chapter = {
  id: number;
  number: number;
  title: string;
  illu: any;
};

const nextChaptersList: Chapter[] = [
  // {
  //   id: 1,
  //   number: 1,
  //   title: "Ile Tonaru : 1er Tenshi",
  //   illu: illu_chap1,
  // },
  {
    id: 2,
    number: 2,
    title: "Puissance. ",
    // title: "Ile Unmoroso",
    illu: illu_chap2,
  },
  {
    id: 3,
    number: 3,
    title: "Technique.",
    // title: "Ile Shinoky",
    illu: illu_chap3,
  },
  {
    id: 4,
    number: 4,
    title: "Préliminaires.",
    // title: "Ile Korosen",
    illu: illu_chap4,
  },
];

const milestones: Milestone[] = [
  // {
  //   id: 1,
  //   type: "episode",
  //   title: "... Janken Tournament !",
  //   description: `Bienvenue dans le Shotam Sekai !`,
  //   illu: pilot,
  //   link: "https://youtu.be/ofn9-OXIcq0",
  //   subtitle: "Release 1",
  // },
  {
    id: 1,
    type: "episode",
    title: "Palier : 0 abonnés",
    description: "Concentre ton Ki, Libère ton Chakra, Maitrise ta Mana.",
    illu: episode0,
    link: "",
    subtitle: "Kamenganomi",
  },
  {
    id: 2,
    type: "episode",
    title: "Palier : 1000 abonnés",
    description: "De la force brute, à l'ancienne.",
    illu: episode1,
    link: "",
    subtitle: "Basu",
  },
  {
    id: 3,
    type: "episode",
    title: "Palier : 2000 abonnés",
    description:
      "Plus haut, plus grand, plus fort.",
    illu: episode2,
    link: "",
    subtitle: "Mecha Kyojin",
  },
  {
    id: 4,
    type: "episode",
    title: "Palier : 3000 abonnés",
    description:
      "Elastico GunUne attaque en souplesse.",
    illu: episode3,
    link: "",
    subtitle: "Elastico Gun",
  },
  {
    id: 5,
    type: "episode",
    title: "Palier : 4000 abonnés",
    description: "Un peu de beauté dans ce monde de brutes.",
    illu: episode4,
    link: "",
    subtitle: "Kira Kira Awa",
  },
  {
    id: 6,
    type: "episode",
    title: "Palier : 5000 abonnés",
    description:
      "Deux pour le prix d'un.",
    illu: episode5,
    link: "",
    subtitle: "Shotou Bunshin",
  },
  // {
  //   id: 6,
  //   type: "episode",
  //   title: "Awa Awa",
  //   description:
  //     "Un peu de beauté dans ce monde de brutes. Vole, Flotte, Brille.",
  //   illu: episode5,
  //   link: "",
  //   subtitle: "Release 5",
  // },
  {
    id: 7,
    type: "episode",
    title: "Palier : 6000 abonnés",
    description: "Quand l'attaque devient défense: un pari risqué !",
    illu: episode6,
    link: "",
    subtitle: "Erase",
  },
  {
    id: 8,
    type: "episode",
    title: "Palier : 7000 abonnés",
    description: "Marche doucement, car tu marches sur mes rêves.",
    illu: episode7,
    link: "",
    subtitle: "Bachisoryou",
  },
  {
    id: 9,
    type: "episode",
    title: "Palier : 8000 abonnés",
    description:
      "Fierté ou intelligence ? L'essence de nos ombres.",
    illu: episode8,
    link: "",
    subtitle: "Kage Manshin",
  },
    {
      id: 10,
      type: "episode",
      title: "Palier : 10000 abonnés",
      description:
        "Pour un chapitre entamé sous le signe du feu, un final givré s'impose.",
      illu: episode9,
      link: "",
      subtitle: "Aisu Kurimu",
    },
];

const ChapterTimeline = ({ milestones }: { milestones: Milestone[] }) => {
  const elWidth =
    document.getElementById("timeline")?.getBoundingClientRect().width ?? 0;
  const scrollWidth = document.getElementById("timeline")?.scrollWidth ?? 0;

  const { ref, inView } = useInView({ threshold: 0.1 });
  const [scrollPerc, setScrollPerc] = useState(0);
  const navigate = useNavigate();

  const timelineScrollHandler = () => {
    const xScroll = document.getElementById("timeline")?.scrollLeft;

    setScrollPerc(xScroll ? (100 * xScroll) / (scrollWidth - elWidth) : 0);
  };

  const watchEpisode = (link: string) => {
    window.open(link);
    // navigate("stream");
  };

  useEffect(() => {
    //@ts-ignore
    document
      .getElementById("timeline")
      ?.addEventListener("scroll", timelineScrollHandler);
    return () => {
      //@ts-ignore
      document
        .getElementById("timeline")
        ?.removeEventListener("scroll", timelineScrollHandler);
    };
  });
  return (
    <div
      id="timeline"
      ref={ref}
      className={classnames("timeline", !inView ? "timelineNotInView" : "")}
    >
      {/* {scrollPerc > 10 && (
        <div className="chevronContainer left">
          <div className="chevron left"></div>
        </div>
      )} */}
      {milestones.map(({ subtitle, title, type, description, illu, link, id }) => (
        <div className="episode">
          <div className="episodeTitleContainer">
            <h2 className="episodeTitle">Release #{id} </h2>
            <h2 className="episodePalier">{title} </h2>
          </div>
          <h2 className="episodeSubtitle">{subtitle} </h2>
          <img
            src={illu}
            width="200px"
            height="300px"
            alt=""
            style={{ opacity: illu === nopicture ? 0.5 : 1 }}
            className="episodeIllu"
          />
          {/* {type === "episode" && (
            <button
              className="seeEpisodeButton"
              onClick={() => watchEpisode(link)}
              disabled={link.length == 0}
            >
              {link.length > 0 ? "Voir le trailer" : "À venir"}
            </button>
          )} */}
          <div className="episodeText">
            <div className="episodeDescription">{description}</div>
          </div>
        </div>
      ))}
      <div
        className={classnames(
          "chevronContainer",
          "right",
          scrollPerc > 2 ? "hidden" : ""
        )}
      // onClick={() => {
      //   //@ts-ignore
      //   document
      //     .getElementById("timeline")
      //     ?.scroll({ left: scrollWidth - elWidth });
      // }}
      >
        <div className="chevron right"></div>
      </div>
    </div>
  );
};

const ChapterTitle = ({
  number,
  title,
  illu,
  style,
}: {
  number: number;
  title: string;
  illu: any;
  style?: any;
}) => {
  return (
    <div className="chapterTitle" {...{ style }}>
      <img
        src={illu}
        width="50px"
        height="50px"
        alt=""
        className="chapterIcon"
      />
      <p className="chapterText">
        <h2 className="chapterIndex">CHAPITRE {number}</h2>
        <h3 className="chapterLabel"><div>{title}</div></h3>
      </p>
    </div>
  );
};

const Home = ({
  visibilityRateFirstView,
}: {
  visibilityRateFirstView: number;
}) => {
  const { ref: ref1, inView: inView1 } = useInView();

  const getMainBackStyle = (rate: number) => {
    const speeding = 5;
    const newRate = Math.pow(rate, speeding);
    return {
      opacity: Math.max(1 - newRate, 0.1),
      // transform: `translateY(${450 * (1 - newRate)}px) `,
    };
  };

  return (
    <Page>
      <>
        <div className="chapter chapter1">
          <ChapterTitle
            number={1}
            title="Dextérité"
            illu={illu_chap1}
            style={getMainBackStyle(visibilityRateFirstView)}
          />

          <div
            className="chapterDescription"
            style={getMainBackStyle(visibilityRateFirstView)}
          >
            <span style={{ color: "inherit" }}> Des techniques secrètes en mode Mudras. Tendinite du petit doigt si t'es pas bien échauffé.</span>{" "}
            <br />
            Qui sera le plus habile des combattants ?
            <br />
            <br />
            1 épisode par semaine sur Twitch, tous les dimanches à 9h30.
            <br />
            Ca commence en Septembre !
            <br />
           
            <span className="cursor">_</span>
            <div className="triUp"></div>
          </div>
          <ChapterTimeline {...{ milestones }} />
        </div>

        <div ref={ref1} className="divider"></div>
        {/* <div className="arc">
          Arc suivant : <br /> <h1>Shotam : Tenshi</h1>
          <h3> Le grand tournoi.</h3>
        </div> */}
        {inView1 && (
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              justifyContent: "center",
              filter: "grayscale(1)",
              background: "#111111EE",
              borderRadius: "10px"
            }}
          >
            {nextChaptersList.map(({ number, title, illu }) => (
              <ChapterTitle {...{ number, title, illu }} />
            ))}
          </div>
        )}
      </>
    </Page>
  );
};

export default Home;
