import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";
import logo from "../../assets/logoblanc.svg";
import cloud from "../../assets/nuage.svg";
import background from "../../assets/background.svg";
import "./drawer.scss";

export const SECTIONS = [
  { path: "", label: "Le concept" },
  { path: "story", label: "Arcs" },
  { path: "shokus", label: "Pouvoirs" },
  { path: "shoshis", label: "Personnages" },
  { path: "shokai", label: "Le Shokai" },
  { path: "secretbook", label: "          " },
];

const Drawer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleNavigation = (path: string) => {
    navigate(path);
    document.getElementById("drawerToggle")?.click();
  };
  return (
    <div id="drawer" className="drawerContainer">
      {SECTIONS.map(({ label, path }) => (
        <div
          className={classnames(
            "drawerTitle",
            location.pathname.slice(1) === path && "active"
          )}
          onClick={() => handleNavigation(path)}
        >
          {label}
        </div>
      ))}
    </div>
  );
};

export default Drawer;
