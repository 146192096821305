import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { useLocation, useNavigate } from "react-router-dom";
import classnames from "classnames";

import DrawerButton from "../drawerButton/drawerButton";
import { SECTIONS } from "../drawer/drawer";

import logo from "../../assets/logoblanc.svg";
import logoText from "../../assets/logoText.svg";

// import background from "../../assets/background.svg";
import "./topbar.scss";
// import { selectIsDrawerOpen, toggleDrawer } from "../../app/navigationSlice";
// import { useAppDispatch, useAppSelector } from "../../app/hooks";

const Topbar = () => {
  //   const dispatch = useAppDispatch();
  //   const isDrawerOpen = useAppSelector(selectIsDrawerOpen);
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (path: string) => {
    navigate(path);
    document.getElementById("drawerToggle")?.click();
  };

  return (
    <div className="topbar">
      {isPortrait && <DrawerButton />}
      {isPortrait ? <img src={logoText} alt="Shotam" width="auto" height="30px" className="logoImage" />:
      <img src={logo} alt="Shotam_Logo" width="30px" height="30px" className="logoImage" />}
      {isPortrait && <div style={{ width: "30px" }}></div>}
      {!isPortrait && (
        <div className="navMenu">
          {SECTIONS.map(({ label, path }) => (
            <div
              className={classnames(
                "menuLink",
                location.pathname.slice(1) === path && "active"
              )}
              onClick={() => handleNavigation(path)}
            >
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Topbar;
