import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  RefObject,
} from "react";
// import { useInView } from "react-intersection-observer";

import Banner from "../banner/banner";
import Page from "../page/page";
import { ReactComponent as ZoomInIcon } from "../../assets/icons/zoomIn.svg";
import { useLocation, useNavigate } from "react-router-dom";

import "./welcomePage.scss";
import classnames from "classnames";
import { useMediaQuery } from "@react-hook/media-query";

const firstPages = [
  <>
    Dans le Shokai, il fut un temps très ancien où les peuples vivaient heureux.
    <br />
    Ère pacifique où naissaient des êtres valeureux, cette ère inspira de
    nombreuses âmes, et forgea de nombreux pouvoirs. <br />
    <br />
    Ainsi pendant cette période, naquirent huit bébés, nourris par la fougue et
    la chaleur de cette ère.
    <br />
    <br /> Huit bébés dont la force allait effrayer les plus téméraires, et le
    nom rester gravé dans l'histoire :<br /> les <i>Utawaleza</i>.
  </>,
  <>
    Quatre de ces bébés croyaient en une justice qui devait être exercée par des
    élus désignés. Ces élus détiendraient donc les pouvoirs nécessaires afin
    d’assurer le bonheur des peuples. <br />
    <br />
    Ils décidèrent donc de prêter leur pouvoir à Quatre empereurs, qu’ils
    estimaient en mesure d’assumer cette conception de la justice.
    <br /> Ces 4 bébés furent nommés, d’un commun accord populaire, les{" "}
    <i>Kenkyodomo</i>.<sup className="pageNoteNumber">(1)</sup>
    <div className="footNote">
      (1) Note du narrateur : Le son Kenkyo nous rappelle évidemment le mot
      japonais 謙虚 signifiant modeste, humble.
    </div>
  </>,
  <>
    Les quatre autres avaient une vision plus libérale de la justice, et
    estimaient qu’elle devrait se passer d’autorité suprême. Ils affirmaient que
    celle-ci devrait s'exprimer selon ses lois naturelles et non régulées, avec
    lesquelles ils interféreraient aussi peu que possible.
    <br />
    <br />
    Ces bébés décidèrent ainsi de conserver leur pouvoir, et les léguer
    éventuellement, par la suite, à un héritier qu’ils estimeraient digne.
    <br /> Ils furent nommés, encore une fois, suite à un consensus tacite et un
    ressenti général des peuples, les <i>Wosagonjetsekadomo</i>.{" "}
    <sup className="pageNoteNumber">(2)</sup>
    <div className="footNote">
      (2) Note du narrateur : L’appellation n’est pas sans rappeler le mot
      wosagonjetseka, qui signifie indomptable en langue Chichewa d’Afrique
      centrale et australe.
    </div>
  </>,
  <>
    Ces 8 bébés cohabitèrent très longtemps, et, malgré leurs divergences,
    furent les garants d’un monde paisible, car étaient tous appréciés au même
    titre par les peuples du Shokai. <br />
    <br /> Mais cet équilibre, qui semblait éternel et inébranlable, fut rompu.
    Pour des raisons encore obscures, dans un contexte plus que trouble, les
    Utawaleza disparurent. <br />
    <br />
  </>,
  <>
    Bien sûr, le monde n’en fut pas immédiatement perturbé, et beaucoup
    pensèrent que l’équilibre des éléments y survivrait malgré tout.
    <br />
    <br />
    Mais de nombreuses mécaniques, invisibles, étaient à l'œuvre. Au fil des
    siècles, l'instabilité grandissait, et le chaos menaçait d’éclore chaque
    jour un peu plus…
  </>,
  <>
    Plus de 400 ans plus tard, une lueur d’espoir surgit, qui pourrait mener,
    dans le meilleur des cas, à un nouvel équilibre durable.
    <br /> <br />
    Mais nul n’aurait deviné que cette lueur d’espoir viendrait d’un autre
    monde, sur lequel même l’habitant le plus fou du Shokai n’aurait pensé
    miser. <br />
    Un monde versatile, faible, égoïste et fainéant, voilà ce qu’ils en
    pensaient...
  </>,
  <>
    Pourtant c’est bien ce Monde qui allait jouer un rôle déterminant dans le
    destin du Shokai, car leurs destins étaient entremêlés bien au-delà de ce
    qu’il pouvait paraître. <br />
    <br />
    Ils allaient découvrir la détermination, la force, la passion et l’endurance
    dont était capable ce Monde jusqu’alors méconnu: le Monde Humain.
  </>,
];

const BookPage = ({ text, pageNumber }) => (
  <div className="bookpage">
    <div className="pageHeader">*</div>
    <div className="pageContent">{text}</div>
    <div className="pageFooter">- {pageNumber} -</div>
  </div>
);

const WelcomePage = () => {
  const isPortrait = useMediaQuery("only screen and (orientation: portrait)");
  const containerRef = useRef<HTMLDivElement>(null);
  const [nextEventArraytext, setNextEventArraytext] = useState([
    "Ouverture de l'arène : ",
    "chargement en cours ...",
  ]);
  const [nextEventUrl, setNextEventUrl] = useState("");
  const [lastTwitchStreamUrl, setLastTwitchStreamUrl] = useState(
    "https://www.twitch.tv/shotamsekai",
  );

  const [focusedZone, setFocusedZone] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://firestore.googleapis.com/v1/projects/shotam-b276d/databases/(default)/documents/general_information/main`,
    )
      .then((res) => {
        res.json().then((obj) => {
          let label = obj.fields.next_event.stringValue;
          let url = obj.fields.next_event_url.stringValue;
          setNextEventArraytext(["Ouverture de l'arène : ", label]);
          setNextEventUrl(url);
        });
      })
      .catch(() => {});
  }, []);

  const getArrayTexts = () => {
    return nextEventArraytext;
  };

  return (
    <Page style={{ paddingBottom: "0px" }}>
      {/* <div className="backPage"></div> */}
      {/*@ts-ignore*/}

      <div className={classnames("welcomeBigtext")}>
        {isPortrait ? (
          <>
            <span className="welcomeBigText2">L'arène de combat</span>
            <span className="welcomeBigText1">en mode</span>
            <div className="welcomeBigText3">MANGA</div>
          </>
        ) : (
          <>
            <span className="welcomeBigText2">L'arène de combat</span>{" "}
            <span className="welcomeBigText1">en mode</span>
            <div className="welcomeBigText3">MANGA</div>
          </>
        )}
      </div>
      <div className="sectionOne">
        <div className="videoAndBannersContainer">
          <div className="videoWrapper">
            {isPortrait ? (
              <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/zAv5sr9duQI?si=uPRv2ffbbYBsETY5"
                title="Shotam : Trailer"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            ) : (
              <div className="trailerContainer">
                <iframe
                  width="500"
                  height="282"
                  src="https://www.youtube.com/embed/zAv5sr9duQI?si=uPRv2ffbbYBsETY5"
                  title="Shotam : Trailer"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            )}
          </div>
          <div className="bannerWrappper">
            {/* <Banner
              isScrolling
              texts={nextEventArraytext}
              style={isPortrait ? { "maxWidth": "80vw" } : { "maxWidth": "500px" }}
            /> */}
            <button
              className="twitchButton"
              onClick={() => window.open(lastTwitchStreamUrl)}
            >
              Voir le Pilote sur Twitch
            </button>
            <button
              className="bookingButton"
              disabled={nextEventUrl === "" || true}
              onClick={() => window.open(nextEventUrl)}
            >
              {">"} Ouverture au public en Septembre !!! {"<"}
            </button>
          </div>
        </div>
      </div>
      <div className="sectionTwo">
        <div className="bookContainer">
          {firstPages.map((page, i) => (
            <BookPage text={page} pageNumber={i} />
          ))}
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button
            className="bookingButton"
            disabled={lastTwitchStreamUrl === ""}
            onClick={() => window.open(lastTwitchStreamUrl)}
          >
            {">"} Voir l'épisode Pilote sur Twitch {"<"}
          </button>
        </div>
      </div>
      <div className="bottomBanner">
        <button onClick={() => navigate("/contact")}>Contact</button>
        <button onClick={() => navigate("/privacy")}>
          Politique de confidentialité
        </button>
        <button onClick={() => navigate("/partners")}>
          Conventions passées
        </button>
      </div>
    </Page>
  );
};

export default WelcomePage;
